import { PriceListResponse, PriceListResponseItem } from 'shared/src/priceList';

export class PriceModule {
  private readonly priceList: PriceListResponse;
  constructor(priceList: PriceListResponse) {
    this.priceList = priceList;
  }

  getPrice(id: string) {
    const price = this.priceList[id];
    if (!price || !isValidPriceItem(price)) {
      throw new Error(`No   price found for product ${id}`);
    }
    return price;
  }
}

const isValidPriceItem = (
  item: PriceListResponseItem
): item is PriceListResponseItem & { price: number; netPrice: number; priceDate: string } => {
  return item.price !== undefined && item.netPrice !== undefined && item.priceDate !== undefined;
};
